<script context="module" lang="ts">
  import Time from "$components/Time.svelte";
  import { instant } from "$utils/temporal";

  const now = instant({ seconds: 30 });
</script>

<script lang="ts">
  import PermitStatus from "./PermitStatus.svelte";

  export let permit: Permit;

  //$: console.log("permit=", permit);

  $: timezone = permit && permit.timezone;
  $: [startstr, endstr] = permit?.valid.interval.split("/") ?? ["", ""];
  $: startutc = startstr && Temporal.Instant.from(startstr);
  //new Date(Math.max(parseISO(startstr), Math.min(issuedutc, Date.now())));
  $: endutc = endstr && Temporal.Instant.from(endstr);

  $: cancelled = true === permit?.cancelled;
  $: revoked = permit?.revoked;
  $: pending =
    permit &&
    !cancelled &&
    Temporal.Instant.compare($now.toInstant(), startutc) < 0; //nowutc < startutc;
  $: expired =
    permit &&
    (permit.expired ||
      (endutc && Temporal.Instant.compare($now.toInstant(), endutc) > 0)); //nowutc > endutc));
  $: active = permit && !expired && !pending && !cancelled;
</script>

{#if permit}
  <dl>
    <dt>Status</dt>
    <dd><PermitStatus item={permit} /></dd>
    {#if revoked}
      <dt>{permit.revoked.title}</dt>
      <dd>
        <Time
          class="status revoked"
          datetime={permit.revoked.datetime}
          timezone={permit.timezone}
          format="h:mm a EEE MMM d yyyy zzz"
        />
      </dd>
      <dt>By</dt>

      {#each [permit.revoked.by, permit.revoked.user]
        .filter((item) => item && item.id)
        .slice(0, 1) as user}
        <dd>
          <a href="mailto:{user.email}"
            ><data class="user {user.type}" value={user.id}
              ><dfn>by</dfn> {user.name}</data
            ></a
          >
        </dd>
      {:else}
        <dd>Self Service Registration</dd>
      {/each}
      {#if permit.revoked.reason}
        <dt>Reason</dt>
        <dd>{permit.revoked.reason}</dd>
      {/if}
    {/if}
    {#if active}
      <dt>Ends</dt>

      <dd>
        {#if !endstr}When Revoked{:else}
          <Time datetime={endstr} {timezone} />
        {/if}
      </dd>
    {:else if pending}
      <dt>Starts</dt>
      <dd>
        <Time
          datetime={startstr}
          timezone={permit.timezone}
          format="h:mm a EEE MMM d yyyy zzz"
        />
      </dd>
    {:else if !revoked}
      <dt>Ended</dt>
      <dd><Time datetime={endstr} {timezone} /></dd>
    {/if}
  </dl>
{/if}
