<script lang="ts" context="module">
  function jpeg(data?: string): string | null {
    if (!data) return null;
    return `data:image/jpeg;base64,${data}`;
  }

  function offenderItemsRelative(
    offenders: Offending,
    now: Readable<Temporal.ZonedDateTime>
  ): Readable<any[]> {
    return derived(
      [offenders.items, offenders.position],
      ([$items, $position]) => {
        return Object.values($items)
          .map((item) => ({
            status: item,
            position: $position, // this will update as position changes...
            distance:
              $position?.coords && item.position
                ? distance(
                    [$position.coords.longitude, $position.coords.latitude],
                    [item.position.lon, item.position.lat],
                    { units: "meters" }
                  ) * 3.28084
                : null, //feet
            age: Temporal.Instant.from(item.received).since(
              item.valid.split("/")[0]
            ),
          }))
          .map((item) => {
            console.log("item=", item);
            return item;
          });
      }
    );
  }
  async function cmd(id: string, cmd: Command | null) {
    //console.log("current cmd=", cmd);
    await fetchCmd(id, cmd);
  }
  async function toggleImagesOn(id: string) {
    //console.log("current cmd=", cmd);
    await fetchCmd(id, {
      images: 1000,
    });
  }
  async function toggleImagesOff(id: string) {
    //console.log("current cmd=", cmd);
    await fetchCmd(id, {
      images: 0,
    });
  }
</script>

<script lang="ts">
  import Loading from "$components/Loading.svelte";
  import { datetime } from "$utils/temporal";
  import MapWebGl from "$components/map/MapWebGL.svelte";
  import MapHeadingMarker from "$components/map/MapHeadingMarker.svelte";
  import MapPositionAccuracyRadiusMarker from "$components/map/MapPositionAccuracyRadiusMarker.svelte";
  import MapPositionAccuracyMarker from "$components/map/MapPositionAccuracyMarker.svelte";
  import { smscreen, localhost } from "$utils/behaviorstores";
  import { onDestroy } from "svelte";
  import MapNavigationControl from "$components/map/MapNavigationControl.svelte";
  import distance from "@turf/distance";
  import {
    connecting,
    connected,
    active,
    offending,
    unwatch,
    watch,
    ago,
    type Offending,
    now,
    type Command,
  } from ".";
  import { derived, writable, type Readable } from "svelte/store";
  import Warning from "$components/Warning.svelte";
  import LprdPosition from "./LprdPosition.svelte";
  import { fetchCmd } from "./api";

  export let id: string;
  let watching: string | null | undefined;

  let state = watch(id);
  //const delta = ago(state);
  // const remote = active(state);
  const satellite = writable<boolean>(false);
  // const offenders = offending(id);
  //const offenderitems = offenderItemsRelative(offenders, now);
  const style = derived(
    [state, satellite],
    ([$state, $satellite]) =>
      $satellite
        ? "https://carto.propertyboss.io/v1/styles/satellite"
        : $state.property
          ? "https://carto.propertyboss.io/v1/styles/protomaps/contrast"
          : "https://carto.propertyboss.io/v1/styles/protomaps/contrast" ||
            "https://carto.propertyboss.io/v1/styles/satellite"
    //"https://carto.propertyboss.io/v1/styles/satellite" ??
    //"https://carto.propertyboss.io/v1/styles/protomaps/contrast" ??
    //"https://carto.propertyboss.io/v1/styles/satellite"
  );
  const zoom = derived([smscreen], ([$smscreen]) => 17);
  //$: watching = $state.client;
  $: if (id != watching) {
    unwatch(watching);
    state = watch((watching = id));
  }

  $: high =
    !!$state?.position?.coords.accuracy &&
    $state?.position.coords.accuracy < 19;
  $: medium =
    !!$state?.position?.coords.accuracy &&
    $state?.position.coords.accuracy >= 19 &&
    $state?.position.coords.accuracy < 38;
  $: low =
    !!$state?.position?.coords.accuracy &&
    $state?.position.coords.accuracy >= 38;

  $: cams = Object.values($state?.cameras ?? []).filter((c) => !!c?.serial);

  $: delta = ago($state, $now);
  $: remote = active($state, $now);

  $: posfreq = $state?.cmd?.position
    ? Temporal.Duration.from({ milliseconds: $state.cmd.position })
    : null;
  $: posage = $state?.position
    ? $now
        .toInstant()
        .since(
          Temporal.Instant.fromEpochMilliseconds($state.position.timestamp)
        )
    : null;

  //$: console.log("$state=", $state);
  //   $: console.log("$remote=", $remote);

  onDestroy(() => {
    unwatch(watching); // cleanup
  });
</script>

<section class="lprd">
  {#if !$connecting && !$connected}
    <Warning message="Unable to connect to monitoring" />
  {/if}
  {#if $connecting}
    <Loading message="Connecting to monitoring" />
  {/if}
  {#if $connected}
    <ol class="lprd device">
      <!-- <li class="online">Connected to monitoring</li> -->
      <li>
        <a
          href={$localhost
            ? `https://localhost:5003/lprd/${id}/status`
            : `https://patroller.propertyboss.dev/lprd/${id}/status`}
          target="_blank">Show Operator View</a
        >
      </li>
      {#if null == remote}
        <li class="loading">
          <Loading message="Looking for Parking Bot {id?.substring(0, 8)}" />
        </li>
      {:else}
        <li class="online">
          <h1>System</h1>
          <dl>
            <dt>Bot</dt>
            <dd>
              <data class="lprd" value={id}>{id?.substring(0, 8)}</data>
            </dd>
            <dt>Status</dt>
            <dd>
              {true === remote ? "Online" : false === remote ? "Offline" : "--"}
            </dd>
            <dt>Last</dt>
            <dd>
              <time datetime={$state?.valid ?? ""}>{$state?.valid ?? ""}</time>
            </dd>
            <dt>Version</dt>
            <dd>
              <data class="version" value={$state?.version ?? ""}
                >{$state.version?.split(" ")?.[0] ?? "--"}</data
              >
            </dd>
            <dt>Temp</dt>
            <dd>
              <data
                class="temperature celcius"
                value={$state.system?.temperature}
                >{$state.system?.temperature.toFixed(1) || "--"} °C</data
              >
            </dd>
          </dl>

          <!-- <time datetime={delta?.toString() ?? ""}
            >{delta && Temporal.Duration.compare(delta, { seconds: 2 }) <= 0
              ? "now"
              : delta?.total("seconds").toFixed(0).padStart(2, "0") + "s"}</time
          > -->
        </li>
        <li>
          <h1>Positioning</h1>
          <dl>
            <dt>Updated</dt>
            <dd>
              {#if $state?.position}
                <time
                  datetime={new Date($state.position.timestamp).toISOString()}
                  >{datetime(
                    Temporal.Instant.fromEpochMilliseconds(
                      $state.position.timestamp
                    )
                  )}</time
                >{:else}<time datetime="">--</time>{/if}
            </dd>
            <dt>Accuracy</dt>
            <dd>
              <data class="accuracy"
                >{$state?.position?.coords.accuracy ?? "--"}m</data
              >
            </dd>
            <dt>Device</dt>
            <dd>
              <data class="device" value={$state?.tpv?.device ?? ""}
                >{$state?.tpv?.device ?? "--"}</data
              >
            </dd>
          </dl>
        </li>
        <li>
          <h1>WWAN</h1>
          <dl>
            <dt>Device</dt>
            <dd>
              <data class="hardware" value={$state?.wwan?.hardware.id ?? ""}
                >{$state?.wwan?.hardware.model ?? "--"}</data
              >
            </dd>
            <dt>SIM</dt>
            <dd>
              <data class="sim" value={$state?.wwan?.connection.id ?? ""}
                >{$state?.wwan?.connection.id?.substring(
                  $state.wwan.connection.id.length - 5,
                  $state.wwan.connection.id.length - 1
                ) ?? "--"}</data
              >
            </dd>
            <dt>Network</dt>
            <dd>
              <data
                class="operator"
                value={$state?.wwan?.connection.operator ?? ""}
                >{$state?.wwan?.connection.operator ?? "--"}</data
              >
            </dd>
            <dt>Type</dt>
            <dd>
              <data class="operator" value={$state?.wwan?.connection.type ?? ""}
                >{$state?.wwan?.connection.type ?? "--"}</data
              >
            </dd>
            <dt>Quality</dt>
            <dd>
              <data
                class="signal"
                value={$state?.wwan?.connection.signal.quality ?? ""}
                >{$state?.wwan
                  ? `${$state.wwan.connection.signal.quality}% (${$state.wwan.connection.signal.recent ? "recent" : "cached"})`
                  : "--"}</data
              >
            </dd>
            <dt>Bars</dt>
            <dd>
              <data
                class="signal"
                value={$state?.wwan?.connection.signal.bars ?? ""}
                >{$state?.wwan
                  ? $state.wwan.connection.signal.bars
                  : "--"}</data
              >
            </dd>
            <dt>Signal</dt>
            <dd>
              <data
                class="signal"
                value={$state?.wwan
                  ? `${$state.wwan.connection.signal.rssi ?? ""},${$state.wwan.connection.signal.rsrp ?? ""},${$state.wwan.connection.signal.rsrq ?? ""},${$state.wwan.connection.signal.snr ?? $state.wwan.connection.signal.sinr ?? ""}`
                  : ""}
                >{$state?.wwan
                  ? `${$state.wwan.connection.signal.rssi ?? "--"},${$state.wwan.connection.signal.rsrp ?? "--"},${$state.wwan.connection.signal.rsrq ?? "--"},${($state.wwan.connection.signal.snr ?? $state.wwan.connection.signal.sinr)?.toFixed(1) ?? "--"}`
                  : "--"}</data
              >
            </dd>
          </dl>
        </li>
        <li>
          <h1>State</h1>
          <dl>
            <dt>Position</dt>
            <dd>{$state?.cmd?.position ?? "--"}</dd>
            <dd>
              <button
                type="button"
                value={id}
                on:click={(e) => cmd(id, { position: 999 })}>Live</button
              >
              <button
                type="button"
                value={id}
                on:click={(e) => cmd(id, { position: 1000 })}>Standard</button
              >
            </dd>
            <dt>Cameras</dt>
            <dd>{$state?.cmd?.cameras ?? "--"}</dd>
            <dt>System</dt>
            <dd>{$state?.cmd?.system ?? "--"}</dd>
            <dt>Images</dt>
            <dd>{$state?.cmd?.images ?? "--"}</dd>
            <dd>
              <!-- <button
                type="button"
                value={id}
                on:click={(e) => cmd(id, { images: true })}>true</button
              >
              <button
                type="button"
                value={id}
                on:click={(e) => cmd(id, { images: 100 })}>0.1s</button
              >
              <button
                type="button"
                value={id}
                on:click={(e) => cmd(id, { images: 500 })}>0.5s</button
              >
              <button
                type="button"
                value={id}
                on:click={(e) => cmd(id, { images: 1000 })}>1.0s</button
              >
              <button
                type="button"
                value={id}
                on:click={(e) => cmd(id, { images: false })}>false</button
              >
              <button
                type="button"
                value={id}
                on:click={(e) => cmd(id, { images: 0 })}>0</button
              > -->
              <button
                type="button"
                value={id}
                on:click={(e) => cmd(id, { images: 2000 })}>Images On</button
              >
              <button
                type="button"
                value={id}
                on:click={(e) => cmd(id, { images: 0 })}>Images Off</button
              >
            </dd>
            <dt>Detections</dt>
            <dd>{$state?.cmd?.detections ?? "--"}</dd>
          </dl>
        </li>
        <li>
          <h1>Survision</h1>
          <!-- <button
            type="button"
            value={id}
            on:click={(e) => cmd(id, { flip: true })}>Flip</button
          >
          <button
            type="button"
            value={id}
            on:click={(e) => cmd(id, { flip: false })}>Unflip</button
          > -->
          <button
            type="button"
            value={id}
            on:click={(e) => cmd(id, { region: "USA/CA>USA>OTHERS" })}
            >CA</button
          >
          <button
            type="button"
            value={id}
            on:click={(e) => cmd(id, { region: "USA/NY>USA>OTHERS" })}
            >NY</button
          >
          <button
            type="button"
            value={id}
            on:click={(e) => cmd(id, { region: "USA/WA>USA>OTHERS" })}
            >WA</button
          >
          <button
            type="button"
            value={id}
            on:click={(e) => cmd(id, { region: "USA/FL>USA>OTHERS" })}
            >FL</button
          >
          <button
            type="button"
            value={id}
            on:click={(e) => cmd(id, { region: "USA/AZ>USA>OTHERS" })}
            >AZ</button
          >
          <button
            type="button"
            value={id}
            on:click={(e) => cmd(id, { region: "USA/IL>USA>OTHERS" })}
            >IL</button
          >
          <button
            type="button"
            value={id}
            on:click={(e) => cmd(id, { region: "USA/UT>USA>OTHERS" })}
            >UT</button
          >
          <!-- <button
            type="button"
            value={id}
            on:click={(e) => cmd(id, { cameras: 30000 })}>30s</button
          > -->
        </li>
        {#each [cams[0], cams[1]] as cam, i}
          <li class="camera" class:offline={!cam}>
            <h1>
              <data class="camer" value={cam?.serial ?? ""}
                >Scanner {i + 1}</data
              >
            </h1>
            {#if cam}
              <data class="detections">
                <!-- {#if $onproperty}
                {$detections[cam?.serial] ?? 0} Plates
              {/if} -->
              </data>
            {/if}
            <dl>
              <dt>Serial</dt>
              <dd>{cam?.serial ?? "--"}</dd>
              <dt>Network</dt>
              <dd>{cam?.host ?? "--"}</dd>
              <dt>Region</dt>
              <dd>{cam?.config?.cameras?.camera.anpr["@context"] ?? "--"}</dd>
              <dt>Flip</dt>
              <dd>
                <button
                  disabled={!cam}
                  value={cam?.serial ?? ""}
                  type="button"
                  on:click={(e) => cmd(id, { flip: e.currentTarget.value })}
                  >{cam?.config?.cameras?.camera.optics["@flip"] ??
                    "--"}</button
                >
              </dd>
            </dl>
            <figure class="image">
              <!-- <img src={$frames[cam?.serial] || ""} /> -->
              <figcaption></figcaption>
            </figure>
          </li>
        {/each}
        <!-- <li>
          <data class="images" value={$state?.cmd?.images ?? ""}
            >Images {$state?.cmd?.images > 0
              ? "On"
              : $state?.cmd?.images === 0
                ? "Off"
                : "--"}</data
          >
          <button type="button" value={id} on:click={(e) => toggleImagesOn(id)}
            >Turn On</button
          >
          <button type="button" value={id} on:click={(e) => toggleImagesOff(id)}
            >Turn Off</button
          >
        </li> -->
        <!-- <li class="property" class:offline={!$state.property}>
          <h1>
            <data class="property" value={$state.property?.id ?? ""}
              >{$state.property?.properties?.name ?? "Off Property"}</data
            >
          </h1>
          <h1>
            <data
              class="detections"
              value={($state.cmd?.detections ?? false) + ""}
              >Scanning {$state.cmd?.detections ? "On" : "Off"}</data
            >
          </h1>
        </li> -->
      {/if}
    </ol>
    {#if $state.position}
      <MapWebGl
        style={$style}
        center={$state.position?.coords && [
          $state.position.coords.longitude,
          $state.position.coords.latitude,
        ]}
        zoom={$zoom}
        bearing={$state.position?.coords && $state.position.coords.heading}
        pitch={0}
        interactive={false}
        zoomable={true}
      >
        <input
          type="checkbox"
          class="satellite"
          checked={$satellite}
          on:change={(e) => satellite.set(e.currentTarget.checked)}
        />
        <data
          class="accuracy meters"
          value={($state.position.coords.accuracy ?? "") + ""}
          >{$state.position?.coords.accuracy
            ? $state.position.coords.accuracy.toFixed(1)
            : "--"} m</data
        >
        <data
          class="speed milesperhour"
          value={($state.position?.coords.speed ?? "") + ""}
          >{$state.position?.coords.speed
            ? ($state.position.coords.speed * 2.236936).toFixed(1)
            : "--"} mph</data
        >
        <!-- <data class="temperature celcius" value={$state.system?.temperature}
              >{$state.system?.temperature.toFixed(1) || "--"} °C</data
            > -->
        <!-- <data class="property" value={$onproperty}
              >{$onproperty ? "on property" : "off property"}</data
            > -->
        {#if $state.position?.coords}
          <MapPositionAccuracyRadiusMarker
            grade={high ? "high" : medium ? "medium" : low ? "low" : ""}
            position={[
              $state.position.coords.longitude,
              $state.position.coords.latitude,
            ]}
            accuracy={$state.position.coords.accuracy}
          />
          <!-- <MapPositionMarker position={$state.position.coords} /> -->
          <!-- <MapPositionAccuracyMarker
              grade={high ? "high" : medium ? "medium" : low ? "low" : ""}
              position={[$state.position.coords.longitude, $state.position.coords.latitude]}
              accuracy={$state.position.coords.accuracy}
            /> -->

          <MapHeadingMarker
            position={[
              $state.position.coords.longitude,
              $state.position.coords.latitude,
            ]}
            heading={$state.position.coords.heading ?? 0}
          />
          <MapNavigationControl />
        {/if}
      </MapWebGl>
    {/if}

    <!-- <ol class="present offenders">
      {#each Object.values($offenderitems) as item}
        <li>
          <h1>
            Offender {item.status.vehicle?.plate ?? item.status.vehicle} Spotted
          </h1>
          <dl>
            <dt>Distance</dt>
            <dd>
              <data class="feet" value={item.distance?.toString() || ""}
                >{item.distance?.toFixed("0") || "--"} feet</data
              >
            </dd>
            <dt>Time</dt>
            <dd>
              <time datetime={item.age.toString()}
                >{item.age.total("seconds").toFixed(0)} seconds ago</time
              >
            </dd>
          </dl>
          <button
            value={item.status.id}
            type="button"
            on:click={(e) => offenders.clear(item.status.id)}>clear</button
          >
          <figure class="image">
            <img src={jpeg(item.status.image?.jpeg?.["#text"])} />
          </figure>
        </li>
      {/each}
    </ol> -->
  {/if}
</section>
