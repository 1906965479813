<script lang="ts">
  import Contact from "$components/Contact.svelte";

  export let permit: Permit;
  $: contacts = [permit?.contact].filter(Boolean);
</script>

{#if permit}
  <ul class="contacts">
    {#each contacts as contact}
      <li class="contact"><Contact {contact} /></li>
    {/each}
  </ul>
{/if}
