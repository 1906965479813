<script lang="ts">
  import RecordItem from "$components/record/RecordItem.svelte";

  export let permit: Permit;
  $: subjects = [
    permit?.media,
    permit?.vehicle,
    ...Object.values(permit?.spaces ?? []),
    permit?.tenant,
  ].filter(Boolean);
</script>

{#if permit}
  <dl>
    <dt>For</dt>
    <dd>
      <ul class="subjects">
        {#each subjects as item}<li>
            <RecordItem {item} />
          </li>{/each}
      </ul>
    </dd>
  </dl>
{/if}
