import get from "lodash-es/get";

export function pricesFromPolicy(policy) {
  if (!policy) return;
  return Object.values(
    get(policy, "pricing.prices.items") ||
    get(policy, "pricing.prices") ||
    get(policy, "prices.items") ||
    {}
  );
}

export function metersFromPolicy(policy) {
  if (!policy) return;
  return Object.values(
    get(policy, "metered.meters.items") ||
    get(policy, "metered.meters") ||
    get(policy, "meters.items") ||
    {}
  );
}

export function generatePolicy(
  scope: string,
  amenity: string = "parking",
  continuous: boolean = false
): PermitIssuePolicy {
  if (!amenity) amenity = "parking";
  if (!scope) return null;
  return {
    // we have to build a policy...
    type: "policy",
    generated: true,
    id: null,
    policy: null, //permit.policy?.policy,
    scope,
    amenity: amenity || "parking",
    title: "Admin Issued Parking",
    audience: {
      title: "Admin Only",
      admin: true,
      public: false,
    },
    issue: {
      enabled: true,
    },
    permit: {
      continuous,
    },
    vehicle:
      "parking" === amenity
        ? {
          request: true,
          required: false,
        }
        : null,
    media: {
      request: continuous,
      required: false,
    },
    tenant: {
      request: true,
      required: false,
    },
    space: {
      request: true,
      required: false,
    },
    name: {
      request: true,
      required: false,
    },
    tel: {
      request: true,
      required: false,
    },
    email: {
      request: true,
      required: false,
    },
    notes: {
      request: true,
      required: false,
    },
  };
}

export function policyCanPermitSpace(
  policy: PermitIssuePolicy,
  item: Space
): boolean {
  if (null == policy) return false;
  if (null == item) return false;
  // if items has to be in it
  if (policy.spaces?.items && !policy.spaces.items[item.id]) return false;
  //   return !!policy.spaces.items[item.id];
  // }
  // has to be parking
  if (
    policy.amenity === "parking" &&
    item.amenity !== "parking" &&
    item.amenity !== "parking_space"
  )
    return false;
  // if (policy.amenity && policy.amenity === item.amenity) return true;
  // if (policy.leisure && policy.leisure === item.leisure) return true;
  return true;

  policy.amenity !== "parking" ||
    policy.amenity === space.amenity ||
    (policy.amenity === "parking" && space.amenity === "parking_space");
  return true;
}

// this might need more work
export function policyCanPermitMedia(
  policy: PermitIssuePolicy,
  item: Media
): boolean {
  if (null == policy) return false;
  if (null == item) return false;
  if (item.amenity === "parking" || policy.amenity === "parking")
    return item.amenity === policy.amenity;
  if (item.amenity && item.amenity === policy.amenity) return true;
  if (item.leisure && item.leisure === policy.leisure) return true;
  if (policy.amenity === "yes") return item.amenity !== "parking";
  if (item.amenity === "yes") return policy.amenity !== "parking";
  if (!item.amenity && !item.leisure) return policy.amenity !== "parking";
  return false;

  (null != media.amenity && media.amenity === values.policy.amenity) ||
    (null != media.leisure && media.leisure === values.policy.leisure);
}
