<script lang="ts">
  export let permit: Permit;
</script>

{#if permit}
  <dl>
    <dt>Type</dt>
    <dd>
      {permit.pass
        ? "Printed Pass"
        : permit.media
          ? "Physical Permit"
          : "Digital Permit"}
    </dd>
    {#if permit.source}
      <dt>Source</dt>
      <dd>{permit.source.name}</dd>
    {/if}
    {#if permit.visiting}
      <dt>Reason</dt>
      <dd>{permit.visiting}</dd>
    {/if}
  </dl>
{/if}
