<script lang="ts">
  import Time from "$components/Time.svelte";

  export let permit: Permit;
  $: [startstr, endstr] = permit?.valid.interval.split("/") ?? ["", ""];
</script>

{#if permit && !permit.cancelled}
  <dl>
    <dt>Start</dt>
    <dd>
      <Time
        datetime={startstr}
        timezone={permit.timezone}
        format="h:mm a EEE MMM d yyyy zzz"
      />
    </dd>
    <dt>End</dt>
    <dd>
      {#if !endstr}When Revoked{:else}
        <Time
          datetime={endstr}
          timezone={permit.timezone}
          format="h:mm a EEE MMM d yyyy zzz"
        />
      {/if}
    </dd>
  </dl>
{/if}
