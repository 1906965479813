<script context="module">
  import { time } from "$utils/timestores";
  import { parseISO } from "date-fns";
  import { format, toZonedTime } from "date-fns-tz";
  import identity from "lodash-es/identity";
  import Contact from "../Contact.svelte";
  import FeesItem from "../FeesItem.svelte";
  const now30s = time({ seconds: 30 });
</script>

<script>
  import ItemExpandContent from "$components/item/ItemExpandContent.svelte";
  import ItemImages from "$components/item/ItemFiles.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import Time from "../Time.svelte";
  import PermitNavItem from "./PermitNavItem.svelte";
  import PermitStatus from "./PermitStatus.svelte";
  import PermitEntry from "./PermitEntry.svelte";
  import PermitSubjects from "./PermitSubjects.svelte";
  import PermitType from "./PermitType.svelte";
  import PermitTitle from "./PermitTitle.svelte";
  import PermitInfo from "./PermitInfo.svelte";
  import PermitContact from "./PermitContact.svelte";
  import PermitStatusDetails from "./PermitStatusDetails.svelte";
  import PermitIssued from "./PermitIssued.svelte";
  import PermitUsage from "./PermitUsage.svelte";
  import PermitValid from "./PermitValid.svelte";

  export let item;

  //$: console.log("permit=", item);
  //$: policy = item && item.policy;

  $: timezone = item && item.timezone;

  //$: fees = item && item.fees && item.fees.items && Object.values(item.fees.items);

  $: issuedutc = item && parseISO(item.issued.utc);

  $: [startstr, endstr] = item?.valid.interval.split("/") ?? ["", ""];
  $: startutc =
    startstr &&
    new Date(Math.max(parseISO(startstr), Math.min(issuedutc, Date.now())));

  $: startlocal = startutc && toZonedTime(startutc, timezone);
  $: endutc = endstr && parseISO(endstr);
  $: endlocal = endutc && toZonedTime(endutc, timezone);

  $: nowutc = item && new Date(Math.max(Date.now(), $now30s));

  $: cancelled = item && item.cancelled;
  $: pending = item && !cancelled && nowutc < startutc;
  $: expired = item && (item.expired || (endutc && nowutc > endutc));
  $: active = item && !expired && !pending && !cancelled;

  //$: console.log("now", nowutc);

  //$: pending = item && !cancelled && nowutc < startutc;
  //$: expired = item && (item.expired || (endutc && nowutc > endutc));
  //$: active = item && !expired && !pending && !cancelled;

  // $: repermit = item && !pending && policy && !item.assigned && (!item.media || !policy.media || !policy.media.request || (policy.media.request && policy.media.repermit && item.expired));

  // $: expirable = item
  //     && ((item.cancelable && pending) // may be cancelled pre-start and not cancelled
  //     || (item.reclaimable && active)); // may be ended early and still running

  //$: files = Object.values(item?.files || []);

  //$: if (item) console.log(item.id, "entry=", item.entry);

  // $: notify = "notify" === $editing.action && $editing.id === item.id;
  // $: revoke =
  //   !item.expired && "revoke" === $editing.action && $editing.id === item.id;
  // $: edit =
  //   item.continuous &&
  //   !item.expired &&
  //   "edit" === $editing.action &&
  //   $editing.id === item.id;
  // $: duplicate = "duplicate" === $editing.action && $editing.id === item.id;
  // let permitDuration;
  // $: if (item.counted.duration) {
  //   permitDuration = Temporal.Duration.from(item.counted.duration)
  //     .total({
  //       unit: "hours",
  //       relativeTo: item.valid.min.datetime,
  //     })
  //     .toLocaleString("en-US", { maximumFractionDigits: 2 });
  // }
</script>

<article class="{item.type} details" data-record="permits/{item?.id}">
  {#if item}
    <header>
      <PermitTitle permit={item} />
    </header>
    <PermitNavItem {item} />
    <!-- <PermitSummaryItem {item}>

    </PermitSummaryItem> -->
    <PermitStatusDetails permit={item} />
    <PermitType permit={item} />
    <PermitSubjects permit={item} />

    <!-- {#each [item.media, item.vehicle, item.space, item.tenant].filter((item) => !!item) as item}
        <dd><RecordItem {item} /></dd>
      {/each} -->

    <!-- <ul class="subjects">
      {#each [item.media, item.vehicle, item.space, item.tenant].filter((item) => !!item) as record}<li
        >
          <Record item={record} />
        </li>{/each}
    </ul> -->

    <PermitInfo permit={item} />
    <PermitContact permit={item} />
    <ItemExpandContent {item}>
      <PermitIssued permit={item} />
      <PermitValid permit={item} />
      <PermitUsage permit={item} />
      <PermitEntry permit={item} />
      <FeesItem {item} />
      <ItemImages {item} create={true} />
    </ItemExpandContent>
  {/if}
</article>
