<script lang="ts" context="module">
  const now30s = time({ seconds: 30 });
</script>

<script lang="ts">
  import RecordItem from "$components/record/RecordItem.svelte";
  import ItemExpandContent from "$components/item/ItemExpandContent.svelte";
  import ItemImages from "$components/item/ItemFiles.svelte";
  import FeesItem from "../FeesItem.svelte";
  import Time from "../Time.svelte";
  import { parseISO } from "date-fns";
  import { time } from "$utils/timestores";
  import { format, toZonedTime } from "date-fns-tz";
  import PermitEntry from "./PermitEntry.svelte";
  import PermitIssued from "./PermitIssued.svelte";
  import PermitSubjects from "./PermitSubjects.svelte";
  import PermitTitle from "./PermitTitle.svelte";
  import PermitContact from "./PermitContact.svelte";
  import PermitType from "./PermitType.svelte";
  import PermitInfo from "./PermitInfo.svelte";
  import PermitStatusDetails from "./PermitStatusDetails.svelte";
  import PermitUsage from "./PermitUsage.svelte";
  import PermitValid from "./PermitValid.svelte";

  export let item: Permit;
  $: subjects = [
    item?.media,
    item?.vehicle,
    ...Object.values(item?.spaces ?? []),
    item?.tenant,
  ].filter((item) => !!item);

  $: timezone = item && item.timezone;

  $: issuedutc = item && parseISO(item.issued.utc);

  $: [startstr, endstr] = item?.valid.interval.split("/") ?? ["", ""];
  $: startutc =
    startstr &&
    new Date(
      Math.max(
        parseISO(startstr).getTime(),
        Math.min(issuedutc?.getTime() ?? Date.now(), Date.now())
      )
    );

  $: endutc = endstr && parseISO(endstr);
  $: endlocal = endutc && toZonedTime(endutc, timezone);
  $: nowutc = item && new Date(Math.max(Date.now(), $now30s.getTime()));

  $: cancelled = item && item.cancelled;
  $: pending = item && !cancelled && nowutc < startutc;
  $: expired = item && (item.expired || (endutc && nowutc > endutc));
  $: active = item && !expired && !pending && !cancelled;

  $: pass = item && item.pass;

  let permitDuration;
  $: if (item.counted.duration) {
    permitDuration = Temporal.Duration.from(item.counted.duration)
      .total({
        unit: "hours",
        relativeTo: item.valid.min.datetime,
      })
      .toLocaleString("en-US", { maximumFractionDigits: 2 });
  }
</script>

{#if item}
  <article class="{item.type} details">
    <header><PermitTitle permit={item} /></header>
    <PermitSubjects permit={item} />
    <PermitInfo permit={item} />
    <PermitIssued permit={item} />
    <ItemExpandContent {item}>
      <PermitStatusDetails permit={item} />
      <PermitType permit={item} />
      <PermitContact permit={item} />
      <PermitValid permit={item} />
      <PermitUsage permit={item} />
      <PermitEntry permit={item} />
      <FeesItem {item} />
      <ItemImages {item} create={true} />
    </ItemExpandContent>
  </article>
{/if}
