<script lang="ts">
  export let permit: Permit;
</script>

{#if permit?.description}
  <dl>
    <dt>Info</dt>
    <dd>{permit.description}</dd>
  </dl>
{/if}
